<template>
  <div>
    <Navbar />
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="text-justify pt-0"
        >  
          <v-card elevation="0" class="mt-0">
            <v-card-text>
              <v-row>
              <v-col cols="12" sm="4">
                <v-img src="@/assets/images/logo/others/collegemanagement.jpg"></v-img>
              </v-col>
              <v-col cols="12" sm="8">
                <h2>The Finest Store Management System</h2><br />
              <p>Most of the education institutes function in similar ways with a minute difference in their operations. College management system first was made on the manual accumulation of data. As the advancement of ERP software solution happened, at present, managing and regulating educational institutions has seen innovations too. Management and regulations of educational institutions are now enthralled on college management software. Management of education institutes were a constant reason of high anxiety for management people.<br/><br/> From starting it included humungous paperwork at the time of admission, keeping records of – daily attendance, library activity, college events, degree and internal grades, laboratory details, staff information, examination schedule and many more. College authorities faced problems with limited options for the management of all these. Most of the admin tasks were needed to be done manually. Being a blessing to the authority, management software came and altered the scenario drastically. College management software was used in many fields earlier. When it intervened in the field of education, it was welcomed with striking results.<br/><br/> From starting it included humungous paperwork at the time of admission, keeping records of – daily attendance, library activity, college events, degree and internal grades, laboratory details, staff information, examination schedule and many more. College authorities faced problems with limited options for the management of all these. Most of the admin tasks were needed to be done manually. Being a blessing to the authority, management software came and altered the scenario drastically. College management software was used in many fields earlier. When it intervened in the field of education, it was welcomed with striking results. </p>
              </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text class="pl-0">
              <v-list>
                <v-list-item v-for="statu in status" :key="statu.detail" dense>
                  <v-list-item-icon>
                    <v-icon>{{ statu.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{ statu.detail }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
          </v-col>
        </v-row>
    </v-container>
    <Content />
    <Footer />
  </div>
</template>
<script>
import Navbar from "./../menue/MenuForOthers";
import Content from "./../menue/Content";
import Footer from "./../menue/Footer";
export default {
  components: {
    Navbar,
    Content,
    Footer,
  },
  data () {
    return {
      status: [
        { icon: "mdi-check-circle", detail: "It is a fast , affordable, low-risk solution with easy implementation and lower maintenance and operational costs", },
        { icon: "mdi-checkbox-marked", detail: "Helps to optimize the use of available resources in a cost effective manner through their proper scheduling and resource allocation.", },
        { icon: "mdi-check-circle", detail: "Integrates seamlessly into the existing IT infrastructure, hence minimal cost overhead.", },
        { icon: "mdi-checkbox-marked", detail: "Is an excellent tool to promote and manage enrollment growth and provide accurate enrollment data.", },
        { icon: "mdi-check-circle", detail: "Acts as a decision support tool for the top management and decision makers by generating real time reports.", },
        { icon: "mdi-checkbox-marked", detail: "Increase faculty time spent on research and interacting with students", },
        { icon: "mdi-check-circle", detail: "Reduces information time lag, hence, reducing the pipeline delay for any activity.", },
        { icon: "mdi-checkbox-marked", detail: "Increases the accountability of the individuals towards their work commitment and foster good working culture in the organization.", },
        { icon: "mdi-check-circle", detail: "Eliminate duplicate data entry and redundant information storage that most often propagates errors.", },
      ]
    };
  },
};
</script>