<template>
  <div>
    <v-row>
      <v-container fluid text-center>
        <v-btn block dark size="display-3"
          ><b>Just Take The Opportunity</b></v-btn
        >
      </v-container>
    </v-row>
    <v-row>
      <v-col justify-center cols="12" md="3">
        <v-card class="mx-auto" flat>
          <div class="text-center">
            <img src="@/assets/images/logo/school.png" width="100" />
          </div>

          <v-card-text class="text-center subtitle-1 font-weight-bold"
            >School Management System
          </v-card-text>

          <v-card-actions>
            <v-btn to="school-management" link block> Explore </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col justify-center cols="12" md="3">
        <v-card class="mx-auto" flat>
          <div class="text-center">
            <img src="@/assets/images/logo/college.png" width="100" />
          </div>

          <v-card-text class="text-center subtitle-1 font-weight-bold"
            >College Management System
          </v-card-text>

          <v-card-actions>
            <v-btn to="college-management" link block> Check In </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col justify-center cols="12" md="3">
        <v-card class="mx-auto" flat>
          <div class="text-center">
            <img src="@/assets/images/logo/store.png" width="100" />
          </div>

          <v-card-text class="text-center subtitle-1 font-weight-bold"
            >Store Management System
          </v-card-text>

          <v-card-actions>
            <v-btn to="store-management" link block> Solutions </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col justify-center cols="12" md="3">
        <v-card class="mx-auto" flat>
          <div class="text-center">
            <img src="@/assets/images/logo/design.png" width="100" />
          </div>

          <v-card-text class="text-center subtitle-1 font-weight-bold"
            >Web Design & Development
          </v-card-text>

          <v-card-actions>
            <v-btn to="web-site-design" link block> Responsive </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
</style>