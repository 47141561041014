import Vue from 'vue'
import App from './App'
import VueRouter from 'vue-router'
import Home from './components/Home';
import Aboutus from './components/Aboutus';
import Contactus from './components/Contactus'
import Agent from './components/Agent'
import SchoolManagement from './components/services/SchoolManagement.vue'
import CollegeManagement from './components/services/CollegeManagement.vue'
import StoreManagement from './components/services/StoreManagement'
import WebsiteDesign from './components/services/WebsiteDesign'

import vuetify from './plugins/vuetify';

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/about-us',
    component: Aboutus,
  },
  {
    path: '/contact-us',
    component: Contactus,
  },
  {
    path: '/agent',
    component: Agent,
  },
  {
    path: '/school-management',
    component: SchoolManagement,
  },
  {
    path: '/college-management',
    component: CollegeManagement,
  },
  {
    path: '/store-management',
    component: StoreManagement,
  },
  {
    path: '/web-site-design',
    component: WebsiteDesign,
  },

]
const router = new VueRouter({
  routes,
  mode: 'history',
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  vuetify,
  router
}).$mount('#app')
