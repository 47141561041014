<template>
  <div>
    <v-toolbar>
      <img class="mr-3" :src="'logo/service.png'" height="50" />
      <v-toolbar-title
        ><span class="display-1 hidden-sm-and-down"
          >Web Service of Bangladesh</span
        >
        <span class="subtitle-1 hidden-md-and-up"
          >Web Service of Bangladesh</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        class="font-weight-bold hidden-sm-and-down"
        color="primary"
        >Login</v-btn
      >
    </v-toolbar>
    <v-responsive aspect-ratio="16/9" class="mt-2 mb-2">
      <v-carousel hide-delimiters cycle height="">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel>
    </v-responsive>
    <Navbar />
    <WelcomeText />
    <Content />
    <Footer />
  </div>
</template>
<script>
import Navbar from "./menue/Navbar";
import WelcomeText from "./menue/WelcomeText";
import Content from "./menue/Content";
import Footer from "./menue/Footer";

export default {
  components: {
    Navbar,
    WelcomeText,
    Content,
    Footer,
  },
  data() {
    return {
      items: [
        {
          src: require('../assets/images/carousel/carousel1.jpg'),
        },
        {
          src: require('../assets/images/carousel/carousel1.jpg'),
        },
        {
          src: require('../assets/images/carousel/carousel1.jpg'),
        },
        {
          src: require('../assets/images/carousel/carousel1.jpg'),
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
