<template>
  <div>
    <Navbar />
    <v-container>
      <v-card flat class="pt-16">
        <v-card-title><v-spacer></v-spacer><span class="display-1">HAVE SOME QUESTIONS?</span><v-spacer></v-spacer></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-icon>mdi-earth</v-icon>
              Tin Khambar More, Arabpur, Kotowali, Jeshore.
              <v-icon>mdi-phone</v-icon> +880 1712 451 994
              <v-icon>mdi-phone</v-icon> +880 1780 089 804
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-container class="text-left">
                <v-row>
                  <v-col cols="12" md="5" class="pt-16 mt-16">
                    <v-img class="ml-auto" max-width="300" :src="'logo/letter-open-logo.png'"></v-img>
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="12" md="5">
                    <v-form lazy-validation ref="form" v-model="valid" onsubmit="return false;">
                      <v-row>
                        <v-col cols="12" md="8" class="pb-0">
                          <v-text-field 
                            v-model="messageInfo.first_name" 
                            outlined 
                            dense 
                            label="First Name"
                            rounded
                            :rules="[v => !!v || 'You cant leave this empty']"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="8" class="pb-0">
                          <v-text-field 
                            v-model="messageInfo.last_name" 
                            outlined 
                            dense 
                            label="Last Name"
                            rounded
                            :rules="[v => !!v || 'You cant leave this empty']"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="8" class="pb-0">
                          <v-text-field 
                            v-model="messageInfo.email" 
                            outlined 
                            dense 
                            label="Email Address"
                            rounded
                            class="shrink"
                            :rules="[
                              v => !!v || 'You cant leave this empty',
                              v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      
                      <v-row>
                        <v-col cols="12" md="8" class="pb-0">
                          <v-text-field 
                            v-model="messageInfo.phone" 
                            outlined 
                            dense 
                            label="Phone Number"
                            rounded
                            class="shrink"
                            :rules="[v => !!v || 'You cant leave this empty']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      
                      <v-row>
                        <v-col cols="12" md="8" class="pb-0">
                          <v-textarea 
                            v-model="messageInfo.message" 
                            outlined
                            :counter="250"
                            label="Your Message"
                            rounded
                            class="shrink"
                            :rules="[
                              v => !!v || 'You cant leave this empty',
                              v => (v && v.length <= 250) || 'Student name must be less than 100 characters',
                            ]"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="8">
                          <v-btn rounded block @click="sendMessage" :disabled="!valid" class="primary">Send Message</v-btn>
                          <!-- <v-btn @click="sendMessage" :disabled="!valid" class="primary">Send Message</v-btn> -->
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./menue/MenuForOthers";
import Footer from "./menue/Footer";
export default {
  title: 'Contact Us',
  components: {
      Navbar, Footer
  },

  data(){
    return {
      valid: true,
      messageInfo: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        message: "",
      }
    }
  },
}
</script>
<style scoped>
</style>