<template>
  <div>
    <v-toolbar>
      <img class="mr-3" :src="'logo/service.png'" height="50" />
      <v-toolbar-title
        ><span class="display-1 hidden-sm-and-down
"
          >Web Service of Bangladesh</span
        >
        <span class="subtitle-1 hidden-md-and-up"
          >Web Service of Bangladesh</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text to="/" v-on="on">Home </v-btn>
          </template>
        </v-menu>

        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text to="" v-on="on"
              >Services <v-icon>mdi-chevron-down</v-icon></v-btn
            >
          </template>

          <v-list>
            <v-list-item to="school-management">
              <v-list-item-title>School Management </v-list-item-title>
            </v-list-item>
            <v-list-item to="college-management">
              <v-list-item-title>College Management </v-list-item-title>
            </v-list-item>
            <v-list-item to="store-management">
              <v-list-item-title>Store Management </v-list-item-title>
            </v-list-item>
            <v-list-item to="web-site-design">
              <v-list-item-title>Web Site Design </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text to="/about-us" v-on="on" depressed>About Us </v-btn>
          </template>
        </v-menu>

        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text to="contact-us" v-on="on">Contact Us </v-btn>
          </template>
        </v-menu>

        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text to="agent" v-on="on">Agent </v-btn>
          </template>
        </v-menu>
      </v-toolbar-items>
      <v-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"
        >mdi-menu</v-icon
      >
    </v-toolbar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      absolute
      right="right"
      width="250"
      id="drawer"
    >
      <template>
        <v-row align="center">
          <v-col cols="6">
            <v-subheader> Menu </v-subheader>
          </v-col>
        </v-row>

        <v-list-item link to="/">
          <v-list-item-content>
            <v-list-item-title> Home </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group append-icon="mdi-chevron-down" class="black--text">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Sevices </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item to="school-management" link style="margin-left: 30px">
            <v-list-item-content>
              <v-list-item-title> School Management </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="college-management" link style="margin-left: 30px">
            <v-list-item-content>
              <v-list-item-title> College Management </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="store-management" link style="margin-left: 30px">
            <v-list-item-content>
              <v-list-item-title> Store Management </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="web-site-design" link style="margin-left: 30px">
            <v-list-item-content>
              <v-list-item-title> Web Design </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item link to="about-us">
          <v-list-item-content>
            <v-list-item-title>About Us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="contact-us">
          <v-list-item-content>
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="agent">
          <v-list-item-content>
            <v-list-item-title>Agent</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appTitleOne: "WBDC",
      appTitleTwo: "ollege",
      drawer: null,
    };
  },
};
</script>