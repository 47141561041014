<template>
  <div>
    <Navbar />
    <div>Agent Page</div>
  </div>
</template>
<script>
import Navbar from "./menue/MenuForOthers";
export default {
  components: {
    Navbar,
  },
};
</script>