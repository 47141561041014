<template>
  <div>
    <!-- <v-container fluid text-center class="hidden-sm-and-down">
      <v-btn dark>Welcome To Our Services</v-btn>
    </v-container> -->
    <!-- <v-container> -->
      <v-row>
        <v-col cols="12">
          <p class="display-1" align="center">Explore Your Digital Future</p>

          <p class="headline pt-0 ma-0" align="center">
            The Easiest Way To Manage
          </p>
          <p class="headline pb-1 mb-0" align="center">
            Your Institution or Business
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" class="text-justify" align="center">
          <p class="pt-4">
            <strong style="color: blue">
              <b>We’re more than just a tech company.</b></strong
            ><br />
            WBD service is a company specializing in digital information and
            communication technologies. It brings together all the skills,
            experience and knowledge for the deployment of technological
            solutions and tools for Companies, Organizations and Institution.
            Based for the last 3 years in Dhaka, Rangpur and Satkhira in
            Bangladesh, our projects cover all the districts of Bangladesh.
          </p>
          <strong>Our ambition:</strong>
          To accelerate access to digital technologies, to guarantee local
          support and to ensure a very high level of services.<br /><br />

          <strong>Our priority:</strong>

          To target your concrete needs in order to build, maintain and optimise
          your digital solutions according to your methods and your work
          process. Whatever the needs, we provide simple, well-adjusted and
          scalable solutions. Through our products and services, we offer
          solutions ensuring all the operational functions necessary for your
          activity while taking into account your budget constraints. Our
          solutions are based on next generation technologies, approved,
          internationally recognised and scalable technical standards.
        </v-col>
        <v-col cols="12" md="4">
          <v-img src="@/assets/images/logo/others/mng.png"></v-img>
        </v-col>
      </v-row>
    <!-- </v-container> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>